import React from "react";
import { icons } from "react-icons/lib";
import oil from "./oil";
import therapie from "./therapie";
import speak from "./speak";

const ServiceContent = [
  {
    icon: therapie,
    title: "Suivi Thérapeutique",
    link: "/Mes-methodes-de-travail",
    descriptions: `Pour apprendre à mieux se connaître, à s'accepter tout en évacuant les charges émotionnelles douloureuses et traumatisantes; et sortir des schémas de vie éprouvants et répétitifs.
    `,
  },
  {
    icon: oil,
    title: "Séance d'Aromathérapie",
    link: "/Seance-d'aromatherapie",
    descriptions: `Ces séances s'adressent aux personnes souhaitant des conseils en huiles essentielles de façon personnalisée afin de soulager et/ou soigner les maux psychiques ou physiques.`,
  },
  {
    icon: speak,
    title:  "Ateliers",
    link: "/Les-differents-types-d'ateliers",
    descriptions:`Différents types d'ateliers s'offrent à vous : que vous soyez professionnel ou particulier. Ces ateliers à thème sont sur mesures et peuvent être adaptés selon vos besoins.`
  },
];


export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01 text-center" style={{'min-height':'330px'}}  >
              <div className="icon" style={{'margin':'auto'}}>
                <val.icon />
              </div>
              <div className="feature-content mt-4">
                <h5><a href={val.link}>{val.title}</a></h5>
                <p style={{'padding-bottom':'35px'}}>{val.descriptions}</p>
                <a href={val.link} style={{'position':'absolute', 'bottom': '10px', 'right': '20px'}}> en savoir plus... </a>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
