import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-4 my-3">
          <div className="img-box">
            <img src="/img/about/yohanna.png" alt="smiling a girl" />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3  className="font-alt" style={{'color':'#78923A;'}}>Yohanna Seychelles</h3>
            <h5 style={{'color':'black'}}>
             Votre <span className="color-theme">psychopraticienne</span> et <span className="color-theme">conseillère en Aromathérapie</span> basée sur <span className="color-theme">l'île de la Réunion</span>, formée en :
            </h5>
            
            <div className="row about-list">
              
              <div className="col-md-12">
              <div className="media" style={{"alignItems":"center"}}>
                  <label>Psychothérapie Psychopathologie
                  </label>
                  <p> École EFPP d'Aix en Provence </p>
                </div>
                <div className="media" style={{"alignItems":"center"}}>
                  <label>Aromathérapie</label>
                  <p> Certification professionnelle </p>
                </div>
                <div className="media" style={{"alignItems":"center"}}>
                  <label>Thérapie par Mouvement Oculaire</label>
                  <p> Attestation de niveau 1 et 2</p>
                </div>
                <div className="media" style={{"alignItems":"center"}}>
                  <label>Psycho-généalogie</label>
                  <p> Attestation de niveau 1 et 2</p>
                  </div>
                <div className="media" style={{"alignItems":"center"}}>
                  <label>Décodage biologique</label>
                  <p> D'après les travaux de Jacques Martel et Christian Flèche. </p>
                </div>
                <p></p>
                <p>
              Mes différents choix de formations me permettent de proposer un accompagnement qui prend en considération chaque personne dans sa globalité.</p> 
              <p>Autrement dit, je tiens compte du vécu personnel, de l'histoire familiale, des douleurs affectives qui en découlent. 
                Mais également, des symptômes et maladies qui sont souvent les révélateurs de blessures émotionnelles inconscientes.</p>
              </div>


              {/* End .col */}

              {/*<div className="col-md-6">
                <div className="media">
                  <label>E-mail</label>
                  <p>info@domain.com</p>
                </div>
                <div className="media">
                  <label>Phone</label>
                  <p>820-885-3321</p>
                </div>
                <div className="media">
                  <label>Skype</label>
                  <p>skype.0404</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div>
              </div>*/ }


              {/* End .col */}
            </div>
            {/* End .row */}
            <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="https://calendly.com/ysey-contact" target="_blank">
                Prendre rendez-vous
              </a>
            </div>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
