import React from "react";
import { icons } from "react-icons/lib";

const TarifContent = [
  {
    icon: "ti-user",
    title: "Thérapie émotionnel individuel",
    descriptions: `Séance d'une heure 50€`,
  },
  {
    icon: "ti-bubbles",
    title: "Conseils en Aromathérapie",
    descriptions: `Séance 30 min: 35€ Séance 1H: 50€`,
  }
];


export default function Tarif() {
  return (
    <>
      <div className="row">
          <div className="col-md-6 col-lg-6 my-3">
            <div className="feature-box-01">
              <div className="feature-content">
                <h5>Suivi Thérapeutique</h5>
                <p>Première séance : 60€ </p>
                <p>Séance de suivi : 50€</p>
                <p> Séance Mouvement Oculaire : 60€ &nbsp; </p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
          <div className="col-md-6 col-lg-6 my-3">
          <div className="feature-box-01">
            <div className="feature-content">
              <h5>Conseils en Aromathérapie</h5>
              <p>Séance de 30 minutes : 35€ </p> 
              <p>Séance d'une heure : 50€</p><p>&nbsp;</p>
            </div>
          </div>
          {/* End .feature-box-01 */}
        </div>
      </div>
    </>
  );
}
