import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "silhouette-02",
      desc: ` Depuis que j'ai commencé les séances, je me sens beaucoup mieux, moralement et mentalement. 
      Ça fait 7 ans que je souffre énormément de la perte de mon papa. J'ai complètement perdu le sommeil, très fatiguée le matin. 
      Pas de motivation. (...) Je vais beaucoup mieux, je sais me calmer maintenant grâce à la thérapeute qui est vraiment à l'écoute,
      elle a beaucoup de patience. Je la recommande.`,
      reviewerName: "Nisha",
    },
    {
      imageName: "silhouette-02",
      desc: `  Grâce à ces séances, je me suis sentie mieux jour après jour. Un réel retour aux sources, un sentiment de confiance et d'aller de l'avant autrement. `,
      reviewerName: "Elodie B.",
    },
    {
      imageName: "silhouette-02",
      desc: `  Mes séances m'aident (…) à sortir des situations pénibles qui ressurgissent dans ma vie à un moment donné. C'est intense, mais je peux compter sur l'aide de ma praticienne pour gérer ces émotions. Au fil des jours les angoisses (…) s’estompent... `,
      reviewerName: "Irène T.",
    },
    {
      imageName: "silhouette-02",
      desc: `Les séances avec Yohanna m'ont permise d'exprimer des émotions dont je n'avais même pas conscience! Ça m'a fait un bien fou et en très peu de temps. Yohanna est très douce et souriante. Elle est aussi très à l'écoute quand il y a des petites crises émotionnelles imprévues ! Elle sait trouver les mots pour apaiser et les astuces pour calmer ces vagues d'émotions.`,
      reviewerName: "Laëticia",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div className="testmonial-box media" key={i}>
            <div className="--avatar--">
              <img
                src={`/img/testimonial/${val.imageName}.svg`}
                style={{'width':'40px'}}
                alt="review comments"
              ></img>
            </div>
            {/* End avatar */}
            <div className="testmonial-text media-body">
              <p>{val.desc}</p>
              <div className="testmonial-info">
                <h6>{val.reviewerName}</h6>
                {/* <div className="rating-value">
                  <i className="ti-star"></i>
                  <span>{val.rating}</span>
                </div> */}
              </div>
            </div>
            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}
